










































































































import {Component, Vue} from 'vue-property-decorator';
import {UserModule} from '@/store/modules/user';

@Component({})
export default class extends Vue {
    private inEditMode = false;

    public get computedPictureUrl() {
        if (!UserModule.pictureUrl) {
            return require('@/assets/image_test_accueil.jpg');
        }
        return `${process.env.VUE_APP_BASE_URL}${UserModule.pictureUrl}`;
    }

    public get computedName() {
        return `${UserModule.firstName} ${UserModule.lastName}`;
    }

    public get computedCompanyPosition() {
        return UserModule.companyPosition;
    }

    public get computedEmail() {
        return UserModule.email;
    }

    public get computedFirstname() {
        return UserModule.firstName;
    }

    public get computedLastName() {
        return UserModule.lastName;
    }

    public get computedAddressLine1() {
        return UserModule.homeAddressLine1;
    }

    public get computedAddressLine2() {
        return UserModule.homeAddressLine2;
    }

    public get computedZipCode() {
        return UserModule.homeZipCode;
    }

    public get computedCity() {
        return UserModule.homeCity;
    }

    public get computedMainBtnLabel() {
        if (!this.inEditMode) {
            return 'Modifier mes informations';
        }
        return 'Valider les modifications';
    }

    public async mainBtnAction() {
        if (!this.inEditMode) {
            this.inEditMode = true;
            return;
        }
        this.inEditMode = false;
    }
}
