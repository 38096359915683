






import {Component, Vue} from 'vue-property-decorator';
import ViewProfile from '@/components/User/ViewProfile.vue';

@Component({
    components: {
        ViewProfile
    }
})
export default class extends Vue {
    
}
